<template>
	<div>
		<div @click="onImageClick">
			<img class="rounded" v-if="photoSrc"
				:alt="alt"
				:id="img_id"
				:src="photoSrc"
			/>

			<img class="rounded" v-else
				:id="img_id"
				:src="require(`GroomyRoot/assets/img/horse_placeholder2.jpg`)"
                :alt="alt"
			/>
		</div>

		<e-fancybox
			ref="fancybox"
			:show-light-box="false"
			:images="[{
				thumb: photoSrc,
				src: photoSrc
			}]"
		></e-fancybox>
	</div>
</template>

<script type="text/javascript">
	export default {
		components: {
			'e-fancybox': () => import('GroomyRoot/components/Utils/EFancybox')
		},
		props: {
            img_id: { type: String },
			filename: { type: String },
			base64: { type: Boolean, default: false },
			alt: { type: String, default: 'Photo cheval' }
		},
		data: () => ({
			photoSrc: null
		}),
		created() {
			this.updateSrc()
		},
		methods:{
			onImageClick () {
				if (this.photoSrc) {
					this.openFancybox()
				} else {
					this.$emit('click')
				}
			},
			openFancybox () {
				this.$refs.fancybox.showImage(0)
			},
			updateSrc () {
				if(this.filename) {
                	if(this.base64) {
                		this.photoSrc = this.filename
                	} else {
						this.$sync.loadOnDemandFile(this.filename, (blob) => {
							this.photoSrc = blob
						})
                    }
                }
                else {
                    this.photoSrc = null
                }
			}
		},
		watch: {
			filename(filename) {
				this.updateSrc()
			}
		}
	}
</script>
